import React, { Component } from 'react'
import { navigate } from 'gatsby'
import CircularProgress from '@material-ui/core/CircularProgress'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js/max'
import { getValidation } from 'utils/validation'
import flatMap from 'lodash/flatMap'

import { formatPhoneNumber } from '../../../api/apply'

import InfoBox from '../../info-box'

import LeadIDinput from './LeadIDinput'
import './pii.scss'

const rules = {
  email: 'required|email',
  phone: 'required|telephone',
  first: 'required|min:3',
  last: 'required|min:3',
  zip: 'required',
}

class Pii extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first: '',
      last: '',
      phone: '',
      email: '',
      zip: '',
      disabled: 'disabled',
      loading: false,
      errors: null,
      forceShowZip: false,
      other: true,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = () => {
    let formKey
    for (formKey in this.state) {
      if (this.props.captive.data[formKey] && this.props.captive.data[formKey] !== '') {
        let val = this.props.captive.data[formKey]
        this.setState(
          {
            [formKey]: val,
          },
          () => {
            this.checkDisabled()
          },
        )
      }
    }
  }
  handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }

    this.setState({ loading: true })

    const phoneNumber = parsePhoneNumberFromString(this.state.phone, 'US')
    const universalleadid = document.getElementById('leadid_token').value

    const xxTrustedFormToken = document.getElementById('xxTrustedFormToken_0')
    const xxTrustedFormCertUrl = document.getElementById('xxTrustedFormCertUrl_0')

    const xxTrustedFormTokenValue = xxTrustedFormToken && xxTrustedFormToken.value
    const xxTrustedFormCertUrlValue = xxTrustedFormCertUrl && xxTrustedFormCertUrl.value
    const data = {
      first: this.state.first,
      last: this.state.last,
      phone: phoneNumber.nationalNumber,
      email: this.state.email,
      leadid: universalleadid,
      zip: this.state.zip,
      Trusted_Form_Token: xxTrustedFormTokenValue,
      Trusted_Form_Cert_URL: xxTrustedFormCertUrlValue,
      other: this.state.other,
      ...(this.props.captive.Sub_ID && { Sub_ID: this.props.captive.Sub_ID }),
      ...(this.props.captive.Pub_ID && { Pub_ID: this.props.captive.Pub_ID }),
    }

    const validation = getValidation({ data, rules })
    validation.fails(() => {
      this.setState({ loading: false })
      this.setState({ errors: validation.errors })

      if (
        validation.errors &&
        validation.errors.errors &&
        validation.errors.errors.zip &&
        validation.errors.errors.zip.length
      ) {
        this.setState({ forceShowZip: true })
      }
    })
    validation.passes(() => {
      this.setState({ errors: null })
      if (!data.leadid) {
        // Force the loader up front before looping starts
        this.props.preSubmitApplication()
        setTimeout(() => {
          this.handleSubmit()
        }, 2000)
      } else {
        this.props.submitData(data)
        const type = this.props.captive.type
        let subId = 'CMI_LifeInsuranceCompanion_Organic'
        if (this.props.captive.src !== '') {
          subId = this.props.captive.src
        }

        const page = 'thank-you'

        //Submit the application and add Promise logic
        this.props
          .submitApplication()
          .then((response) => {
            // Update our data layer before navigating
            const { captive } = this.props

            if (window && window.dataLayer) {
              window.dataLayer.push({
                type: captive.type,
                src: captive.src,
                phone: formatPhoneNumber(captive.data.phone),
                zip: captive.data.zip,
              })
            }

            navigate('/' + page + '/?sub_id=' + subId)
          })
          .catch((err) => {
            this.setState({ loading: false })
          })
      }
    })
  }
  handleChange = (event) => {
    const id = event.target.id
    const type = event.target.type
    let val = type === 'checkbox' ? event.target.checked : event.target.value

    if (id === 'phone') {
      const phoneNumber = new AsYouType('US').input(val)
      val = phoneNumber
    }

    this.setState({ [id]: val }, () => {
      this.checkDisabled()
    })
  }
  checkDisabled = () => {
    let phoneValid = false
    const phoneNumber = parsePhoneNumberFromString(this.state.phone, 'US')
    if (phoneNumber) {
      phoneValid = phoneNumber.isValid()
    }

    let disabled =
      this.state.first === '' ||
      this.state.first.length < 3 ||
      this.state.last === '' ||
      this.state.last.length < 3 ||
      !phoneValid ||
      this.state.email === '' ||
      this.state.email.length < 6

    this.setState({ disabled: disabled })
  }
  determineCheckboxText = () => {
    return (
      'Yes, I would also like free quotes for ' +
      (this.props.captive.type === 'medicare' ? 'Medicare' : 'Health Insurance') +
      ' plans?'
    )
  }

  render = () => {
    const { captive = {} } = this.props
    const { errors } = this.state
    const { api = {} } = captive
    let message = api.error
      ? api.message
      : `Last question! We just need to know how to get in touch with you.`
    let alertClasses = api.error ? `error` : `success`
    return (
      <div>
        <InfoBox text={message} classNames={alertClasses} />
        <div id="pii">
          <strong className="h4">How may we contact you?</strong>
          <form
            action="#"
            onSubmit={this.handleSubmit}
            className="form-validation"
            noValidate="novalidate"
          >
            <LeadIDinput />
            <div className="row">
              <div className="col half-width">
                <label htmlFor="first">First Name</label>
                <input
                  maxLength="25"
                  type="text"
                  id="first"
                  data-required="true"
                  value={this.state.first}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col half-width">
                <label htmlFor="last">Last Name</label>
                <input
                  maxLength="25"
                  type="text"
                  id="last"
                  data-required="true"
                  value={this.state.last}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col full-width">
                <label htmlFor="phone">Phone Number</label>
                <input
                  maxLength="16"
                  type="tel"
                  id="phone"
                  data-required="true"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col full-width">
                <label htmlFor="email">Email</label>
                <input
                  maxLength="80"
                  type="email"
                  id="email"
                  data-required="true"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {this.state.forceShowZip && (
              <div className="row">
                <div className="col full-width">
                  <label htmlFor="zip">Zip Code</label>
                  <input
                    className="data-hj-whitelist"
                    maxLength="5"
                    type="text"
                    id="zip"
                    data-required="true"
                    value={this.state.zip}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col full-width check-other">
                <div className="row">
                  <input
                    type="checkbox"
                    id="other"
                    checked={this.state.other}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="other">{this.determineCheckboxText()}</label>
                </div>
              </div>
            </div>
            <div className="btn-wrap text-center">
              {(api.pending || this.state.loading) && <CircularProgress />}
              {!api.pending && !this.state.loading && (
                <button
                  type="submit"
                  disabled={this.state.disabled}
                  className={`btn` + (this.state.disabled ? ` disabled` : ``)}
                >
                  View Plans
                </button>
              )}
            </div>
            {errors && (
              <div className="mt3">
                {flatMap(
                  Object.keys(errors.errors).map((x) => errors.errors[x]),
                  (text, i) => (
                    <InfoBox key={i} text={text[0]} error />
                  ),
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}

export default Pii
