import { connect } from 'react-redux'

import { nextStep, submitData, submitDob } from '../../../reducers/captive'

import Birth from './birth'

export default connect((state) => state, {
  nextStep,
  submitData,
  submitDob,
})(Birth)
