import { connect } from 'react-redux'

import Conditions from './conditions'

import { nextStep, submitData } from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitData,
})(Conditions)
