import React, { Component } from 'react'
import Slider from '@material-ui/lab/Slider'
import { Helmet } from 'react-helmet'

import './coverage.scss'

class Coverage extends Component {
  constructor(props) {
    super(props)
    this.state = { coverage: '', disabled: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    const coverage = this.state.coverage === '' ? '5000' : this.state.coverage
    this.props.submitData({ coverage: coverage })
    this.props.handleNext('coverage')
  }
  handleChange = (event) => {
    const id = event.target.id
    const type = event.target.type
    const val = type === 'checkbox' ? event.target.checked : event.target.value

    this.setState({ [id]: val }, () => {
      //this.checkDisabled()
    })
  }
  checkDisabled = () => {
    let disabled = this.state.coverage === '' ? 'disabled' : ''
    this.setState({ disabled: disabled })
  }

  render = () => (
    <div id="height">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">How much coverage are you looking for?</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col full-width">
            <select id="coverage" onChange={this.handleChange}>
              <option value="5000">$5,000</option>
              <option value="10000">$10,000</option>
              <option value="20000">$20,000</option>
              <option value="50000">$50,000</option>
              <option value="100000">$100,000</option>
              <option value="200000">$200,000</option>
              <option value="300000">$300,000</option>
              <option value="400000">$400,000</option>
              <option value="500000">$500,000</option>
              <option value="750000">$750,000</option>
              <option value="1000000">$1,000,000</option>
            </select>
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={this.state.disabled}
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default Coverage
