import React, { Component } from 'react'

const Months = [
  { value: '1', name: 'January', days: 31 },
  { value: '2', name: 'February', days: 28 },
  { value: '3', name: 'March', days: 31 },
  { value: '4', name: 'April', days: 30 },
  { value: '5', name: 'May', days: 31 },
  { value: '6', name: 'June', days: 30 },
  { value: '7', name: 'July', days: 31 },
  { value: '8', name: 'August', days: 31 },
  { value: '9', name: 'September', days: 30 },
  { value: '10', name: 'October', days: 31 },
  { value: '11', name: 'November', days: 30 },
  { value: '12', name: 'December', days: 31 },
]

const calcluateAge = (day, month, year) => {
  year = year.length === 2 ? '19' + year : year
  const now = new Date()
  const dob = new Date(year, month - 1, day)
  const diffMs = now.getTime() - dob.getTime()
  const diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)

  return diffYears
}

class Birth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      month: 0,
      day: 0,
      year: '1953',
      // disabled: 'disabled',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = (prevProps) => {
    let formKey
    for (formKey in this.state) {
      if (this.props.captive.data[formKey] && this.props.captive.data[formKey] !== '') {
        let val = this.props.captive.data[formKey]
        this.setState(
          {
            [formKey]: val,
          },
          () => {
            this.checkDob()
          },
        )
      }
    }
    // window.jcf.refreshAll()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const age = calcluateAge(this.state.month, this.state.day, this.state.year)
    console.log('agggee: ', age)

    if (this.state.month === 0 || this.state.day === 0) {
      const month = this.state.month === 0 ? 1 : parseInt(this.state.month, 10)
      const day = this.state.day === 0 ? 1 : parseInt(this.state.day, 10)
      this.props.submitData({ day: day, month: month })
      this.props.submitDob(this.state.year)
    }

    this.props.handleNext('birth')
  }
  handleChange(event) {
    const id = event.target.id
    const type = event.target.type
    let val = type === 'checkbox' ? event.target.checked : event.target.value

    this.setState({ [id]: val }, () => {
      if (['month', 'day', 'year'].includes(id)) {
        this.checkDob()
      }
    })
  }
  checkDob = () => {
    if (this.state.month !== '' && this.state.day !== '') {
      const day = parseInt(this.state.day, 10)
      const month = parseInt(this.state.month, 10)
      this.props.submitData({ day: day, month: month })
    }

    if (this.state.month !== '' && this.state.day !== '' && this.state.year !== '') {
      this.props.submitDob(this.state.year)
    }
  }

  renderDays = () => {
    let dayCount = 31
    for (let d = 0; d < Object.keys(Months).length; d++) {
      if (Months[d].value === this.state.month) {
        dayCount = Months[d].days
      }
    }
    let items = []
    for (let i = 1; i <= dayCount; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>,
      )
    }
    return (
      <select value={this.state.day} id="day" onChange={this.handleChange}>
        <option value="">Day</option>
        {items}
      </select>
    )
  }
  renderYears = () => {
    let items = []
    for (let i = 2000; i >= 1910; i--) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>,
      )
    }
    return (
      <select value={this.state.year} id="year" onChange={this.handleChange}>
        <option value="">Year</option>
        {items}
      </select>
    )
  }

  render = () => (
    <div>
      <strong className="h4 mb2">What is your birthdate?*</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col third-width">
            <label htmlFor="weight" className="hidden">
              Month
            </label>
            <select id="month" value={this.state.month} onChange={this.handleChange}>
              <option value="">Month</option>
              {Months.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="col third-width">
            <label htmlFor="weight" className="hidden">
              Day
            </label>
            {this.renderDays()}
          </div>

          <div className="col third-width">
            <label htmlFor="weight" className="hidden">
              Year
            </label>
            {this.renderYears()}
          </div>
        </div>
        <p className="mb0 mt3 t-smallish t-grey">
          <i>*This is not required</i>
        </p>
        <div className="btn-wrap text-center">
          <button type="submit" className="btn">
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default Birth
