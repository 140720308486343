import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import LinearProgress from '@material-ui/core/LinearProgress'

import Modal from '../../modal'
import zip from '../zip'
import dobmonth from '../dob'
import dobday from '../dob'
import dobyear from '../dob'
import birth from '../birth'
import bday from '../bday'
import existing from '../existing'
import gender from '../gender'
import address from '../address'
import pii from '../pii'
import income from '../income'
import household from '../household'
import height from '../height'
import weight from '../weight'
import tobacco from '../tobacco'
import coverage from '../coverage'
import conditions from '../conditions'

import './main.scss'

// make references to the components by type
const Components = {
  dobmonth,
  dobday,
  dobyear,
  zip,
  existing,
  gender,
  address,
  pii,
  income,
  household,
  height,
  weight,
  tobacco,
  coverage,
  conditions,
  birth,
  bday,
}

class Main extends Component {
  constructor(props) {
    super(props)

    //this.handleSectionRedirects('', this.props.location.pathname.replace('/apply/', ''), '', this.props.captive.step);
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleModalLink = this.handleModalLink.bind(this)
  }
  componentDidUpdate(prevProps) {
    this.handleSectionRedirects(
      prevProps.location.pathname.replace('/apply/', ''),
      this.props.location.pathname.replace('/apply/', ''),
      prevProps.captive.step,
      this.props.captive.step,
    )
  }
  handleSectionRedirects = (prevPath, currentPath, prevStep, currentStep) => {
    if (prevPath === '' && prevStep === '' && currentPath !== currentStep) {
      this.props.goToStep(currentPath)
      navigate(`/apply/${currentPath}`)
    } else if (prevPath !== currentPath && currentPath !== currentStep) {
      this.props.goToStep(currentPath, false)
    }
  }
  handleNext = (val) => {
    const nextStep = this.props.nextStep(this.props.captive.step)
    navigate(`/apply/${nextStep}`)
  }
  handlePrevious = () => {
    const nextStep = this.props.nextStep(this.props.captive.step, true)
    navigate(`/apply/${nextStep}`)
  }
  handleModalLink = (e, type) => {
    e.preventDefault()
    this.props.toggleModal(type)
  }
  render = () => {
    let Component = Components[this.props.captive.step]
    let showPrevious =
      (this.props.captive.progress > 0 && this.props.captive.step !== 'pii') ||
      this.props.captive.api.error
    return (
      <div id="apply-flow" className={`form-box ${this.props.captive.step}`}>
        <div className="contacts-form alt text-center">
          {this.props.captive.progress > 0 && this.props.captive.progress < 100 && (
            <LinearProgress
              variant="determinate"
              value={this.props.captive.progress}
              classes={{ root: 'progress-bar-holder', bar: 'progress-bar' }}
            />
          )}
          <Component handleNext={this.handleNext} />
          {showPrevious && (
            <span className="previous" onClick={this.handlePrevious}>
              &larr;&nbsp; Previous Question
            </span>
          )}
          {this.props.captive.step === 'pii' && (
            <>
              <input type="hidden" id="leadid_tcpa_disclosure" />
              <label className="tcpa" for="leadid_tcpa_disclosure">
                By clicking the button and submitting this form, I agree that I am 18+
                years old and agree to the{' '}
                <Link to="/" onClick={(e) => this.handleModalLink(e, 'privacy')}>
                  Privacy Policy
                </Link>
                ,{' '}
                <Link to="/" onClick={(e) => this.handleModalLink(e, 'terms')}>
                  Terms &amp; Conditions
                </Link>{' '}
                and{' '}
                <a target="_blank" href="/ccpa">
                  CCPA
                </a>
                . By clicking the button and submitting this form, I provide my signature
                giving express consent to receive marketing communications via artificial
                or pre-recorded voice, emails, live phone calls, pre-recorded calls,
                postal mail, text messages via SMS or MMS and other forms of communication
                regarding offers of Life Insurance, Final Expense, Burial Insurance, and
                other senior home, health and assistance products from PolicyScout,
                GuideToInsure, InsureaLife and/or one or more of it’s{' '}
                <Link to="/" onClick={(e) => this.handleModalLink(e, 'partners')}>
                  marketing partners
                </Link>{' '}
                and agents to the number(s) and/or email I provided, including a mobile
                phone, even if I am on a state or federal Do Not Call and/or Do Not Email
                registry using an automated telephone dialing system. Agents are not
                connected with or endorsed by the U.S. government or the federal Medicare
                program. The list of companies participating are subject to change. I
                understand my wireless carrier may impose charges for calls or texts. I
                understand that my consent to receive communications is not required as a
                condition of purchase and I may revoke my consent at any time by
                contacting us via 1-833-259-7339 or email at opt.out@policyscout.com so
                that you may be placed on our do-not-call and/or our do-not-email list. To
                receive quotes without providing consent, please call at 1-833-656-0487.
              </label>
              <Modal type="partners" />
            </>
          )}
        </div>
        <Helmet key={`jornaya`}>
          <script key={`jornayascript`} id="LeadiDscript" type="text/javascript">
            {`
                        (function() {
                        var s = document.createElement('script');
                        s.id = 'LeadiDscript_campaign';
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = '//create.lidstatic.com/campaign/de9f03d0-57cb-2e5f-285f-7413a4efea07.js?snippet_version=2';
                        var LeadiDscript = document.getElementById('LeadiDscript');
                        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
                        })();
                    `}
          </script>
          <noscript
            key={`jornayanoscript`}
          >{`<img src='//create.leadid.com/noscript.gif?lac=175deec7-46ac-376d-3c9f-2e7d7b607660&lck=de9f03d0-57cb-2e5f-285f-7413a4efea07&snippet_version=2' />`}</noscript>
        </Helmet>
      </div>
    )
  }
}

export default Main
