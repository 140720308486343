import { connect } from 'react-redux'

import { nextStep, submitData, submitDob } from '../../../reducers/captive'

import Bday from './bday'

export default connect((state) => state, {
  nextStep,
  submitData,
  submitDob,
})(Bday)
