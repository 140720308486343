import Validator from 'validatorjs'
// import get from 'lodash/get'
// import axios from 'axios'

// const emailVerifyUrl = (email) =>
//   `https://validation.api.hivehq.co/v1/emailVerifyProxy?email=${email}&domain=lifeinsurancecompanion.com`

// const zipVerifyUrl = (zip) =>
//   `https://validation-dev.api.hivehq.co/v1/zipVerifyProxy?zipcode=${zip}&domain=lifeinsurancecompanion.com`

// export const testZip = async (zip) => {
//   return await axios.get(zipVerifyUrl(zip)).then((x) => x.data)
// }

// export const testEmail = async (email) =>
//   await axios.get(emailVerifyUrl(email)).then((x) => x.data)

export const getValidation = ({ data = {}, rules = {} } = {}) => {
  const validation = new Validator(data, rules)
  Validator.register(
    'telephone',
    function (value, requirement, attribute) {
      // requirement parameter defaults to null
      return /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/.test(value)
    },
    'The :attribute phone number is not in the format.',
  )

  Validator.register(
    'zip',
    function (value, requirement, attribute) {
      // requirement parameter defaults to null
      return value.match(/^\d{5}(-?\d{4})?$/)
    },
    'Invalid ZIP Code.',
  )

  // Validator.registerAsync('xverify-zip', async function (value, attribute, req, passes) {
  //   // requirement parameter defaults to null
  //   const data = await testZip(value)
  //   const status = get(data, 'data.zipdata.status', false)
  //   if (status === 'valid') {
  //     return passes()
  //     // return true
  //   } else {
  //     return passes(false, 'Invalid Zip.')
  //     // return false
  //   }
  // })

  // Validator.registerAsync('xverify', async function (value, attribute, req, passes) {
  //   // requirement parameter defaults to null
  //   const data = await testEmail(value)
  //   const status = get(data, 'data.status', false)
  //   if (status === 'valid') {
  //     return passes()
  //     // return true
  //   } else {
  //     return passes(false, 'Invalid Email.')
  //     // return false
  //   }
  // })

  return validation
}
