import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import { getValidation } from 'utils/validation'
import flatMap from 'lodash/flatMap'

import InfoBox from '../../info-box'

const rules = {
  zip: 'zip',
  address: 'required',
}

const Address = ({ submitData, handleNext, captive }) => {
  const initValue = {
    address: get(captive, 'data.address', ''),
    zip: get(captive, 'data.zip', ''),
  }
  const zipExists = initValue.zip.length === 5
  const [form, setForm] = useState(initValue)
  const [disabled, setDisabled] = useState(!initValue.address.length > 5 && !zipExists)
  const [errors, setErrors] = useState(null)

  const checkDisabled = () => {
    const disabled =
      form.address === '' ||
      form.address.length < 5 ||
      (captive.includeAddress && (form.zip === '' || form.zip.length !== 5))
    setDisabled(disabled)
  }

  useEffect(() => {
    checkDisabled()
  }, [form])

  const handleSubmit = (e) => {
    e.preventDefault()
    const validation = getValidation({ data: form, rules })
    if (!validation.passes()) {
      setErrors(validation.errors)
    } else {
      setErrors(null)
      submitData(form)
      handleNext('address')
    }
  }

  const handleChange = ({ target: { id, value } }) => {
    setForm((form) => ({ ...form, [id]: value }))
  }

  return (
    <div>
      {captive.type === 'health' &&
        captive.data.tobacco &&
        captive.data.tobacco === 'Yes' && (
          <InfoBox text="Don’t fret. We have affordable plans available for tobacco users." />
        )}
      <strong className="h4">Please enter your street address</strong>
      <form action="#" onSubmit={handleSubmit} className="form-validation">
        <div className="row">
          <div className="col full-width">
            <label htmlFor="address" className="t-left t-med">
              Street Address
            </label>
            <input
              className="data-hj-whitelist"
              maxLength="80"
              type="text"
              id="address"
              data-required="true"
              value={form.address}
              onChange={handleChange}
            />
          </div>
        </div>
        {!zipExists && (
          <div className="row">
            <div className="col full-width">
              <label htmlFor="zip" className="t-left t-med">
                Zip Code
              </label>
              <input
                className="data-hj-whitelist"
                maxLength="5"
                type="text"
                id="zip"
                data-required="true"
                value={form.zip}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={disabled ? 'disabled' : ''}
            className={`btn` + (disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
        {errors && (
          <div className="mt3">
            {flatMap(
              Object.keys(errors.errors).map((x) => errors.errors[x]),
              (text, i) => (
                <InfoBox key={i} text={text} error />
              ),
            )}
          </div>
        )}
      </form>
    </div>
  )
}

export default Address
