import React from 'react'
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import UseInsurance from '../components/sections/use-insurance'
import Partners from '../components/sections/partners'
import Main from '../components/apply/main'
import Header from '../components/header'

import BenefitBg from '../images/benefit-bg.png'
import HeaderLinesBg from '../images/header-single-bg.svg'

const ApplyPage = ({ location, captive }) => {
  let headerClassName = ''
  let introProps = {
    showTitle: false,
    colType: 'full-col',
    bgImage: BenefitBg,
    ...((captive?.flow === 'lifeF2' ||
      captive?.flow === 'lifeF3' ||
      captive?.flow === 'lifeF4') && { bgImage: '' }),
  }

  if (captive && typeof captive.altHeaderLayout !== 'undefined') {
    headerClassName = captive.altHeaderLayout ? 'large' : ''
    introProps = {
      ...introProps,
      bgImage: HeaderLinesBg,
      classNames: 'single',
      ...((captive?.flow === 'lifeF2' ||
        captive?.flow === 'lifeF3' ||
        captive?.flow === 'lifeF4') && { bgImage: '' }),
    }
  }

  return (
    <Layout
      phone="877-755-6327"
      location={location}
      footerAlt={
        captive?.flow === 'lifeF2' ||
        captive?.flow === 'lifeF3' ||
        captive?.flow === 'lifeF4'
      }
    >
      <SEO
        title="Apply for Life Insurance"
        keywords={[`life`, `insurance`, `plans`, `affordable`, `supplement`]}
      />
      <Header phone="877-755-6327" grayLogo className={headerClassName} />
      <Intro {...introProps}>
        <Main location={location} />
      </Intro>
      <main id="main">
        <Partners alt2={captive?.flow === 'lifeF2'} />
        <UseInsurance
          alt={
            captive?.flow === 'lifeF2' ||
            captive?.flow === 'lifeF3' ||
            captive?.flow === 'lifeF4'
          }
        />
      </main>
    </Layout>
  )
}

export default connect((state) => state, {})(ApplyPage)
