import React, { Component } from 'react'
import styles from './styles.module.scss'

const calcluateAge = (day, month, year) => {
  year = year.length === 2 ? '19' + year : year
  const now = new Date()
  const dob = new Date(year, month - 1, day)
  const diffMs = now.getTime() - dob.getTime()
  const diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)

  return diffYears
}

class Bday extends Component {
  constructor(props) {
    super(props)
    this.state = {
      month: '',
      day: '',
      year: '',
      // disabled: 'disabled',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = (prevProps) => {
    const monthInput = document.getElementById('month')
    monthInput.focus()
    let formKey
    for (formKey in this.state) {
      if (this.props.captive.data[formKey] && this.props.captive.data[formKey] !== '') {
        let val = this.props.captive.data[formKey]
        this.setState(
          {
            [formKey]: val,
          },
          () => {
            this.checkDob()
          },
        )
      }
    }
    // window.jcf.refreshAll()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const age = calcluateAge(this.state.month, this.state.day, this.state.year)
    console.log('agggee: ', age)

    if (this.state.month === 0 || this.state.day === 0) {
      const month = this.state.month === 0 ? 1 : parseInt(this.state.month, 10)
      const day = this.state.day === 0 ? 1 : parseInt(this.state.day, 10)
      this.props.submitData({ day: day, month: month })
      this.props.submitDob(this.state.year)
    }

    this.props.handleNext('birth')
  }
  handleChange(event) {
    const id = event.target.id
    const type = event.target.type
    let val = type === 'checkbox' ? event.target.checked : event.target.value

    if (val !== '' && val !== 0 && val !== '0' && !Number(val)) {
      return
    }

    this.setState({ [id]: val }, () => {
      if (['month', 'day', 'year'].includes(id)) {
        this.checkDob()
      }
    })

    if ((id === 'month' || id === 'day') && val?.length === 2) {
      // go to next input
      const nextId = id === 'month' ? 'day' : 'year'
      const nextInput = document.getElementById(nextId)
      nextInput.focus()
    }
  }
  checkDob = () => {
    if (this.state.month !== '' && this.state.day !== '') {
      const day = parseInt(this.state.day, 10)
      const month = parseInt(this.state.month, 10)
      this.props.submitData({ day: day, month: month })
    }

    if (this.state.month !== '' && this.state.day !== '' && this.state.year !== '') {
      this.props.submitDob(this.state.year)
    }
  }

  render = () => (
    <div>
      <strong className="h4 mb2">What is your birthdate?*</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className={styles.bdayContainer}>
          <div>
            <label htmlFor="weight">Month</label>
            <input
              id="month"
              value={this.state.month}
              onChange={this.handleChange}
              placeholder="MM"
              maxLength={2}
            />
          </div>

          <div>
            <label htmlFor="weight">Day</label>
            <input
              value={this.state.day}
              id="day"
              onChange={this.handleChange}
              placeholder="DD"
              maxLength={2}
            />
          </div>

          <div>
            <label htmlFor="weight">Year</label>
            <input
              value={this.state.year}
              id="year"
              onChange={this.handleChange}
              placeholder="YYYY"
              maxLength={4}
            />
          </div>
        </div>
        <p className="mb0 mt3 t-smallish t-grey">
          <i>*This is not required</i>
        </p>
        <div className="btn-wrap text-center">
          <button type="submit" className="btn">
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default Bday
