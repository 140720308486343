import React, { Component } from 'react'

import './conditions.scss'

const conditionList = [
  { key: 'alcohol', value: 'Alcohol or Substance Abuse' },
  { key: 'alzheimers', value: "Alzheimer's disease or Dementia" },
  { key: 'oxygen', value: 'Any condition requiring use of oxygen' },
  { key: 'chf', value: 'Congestive Heart Failure(CHF)' },
  { key: 'heart', value: 'Heart Attack or Stroke' },
  { key: 'hiv', value: 'HIV or AIDS' },
  { key: 'melanoma', value: 'Internal cancer, melanoma or leukemia' },
  { key: 'kidney', value: 'Kidney disease requiring dialysis' },
  { key: 'als', value: "Lou Gehrig's Disease(ALS)" },
  { key: 'parkisons', value: "Neuromuscular(Parkinson's, CP, MS)" },
]

class Conditions extends Component {
  constructor(props) {
    super(props)
    let conditions = ['none']
    if (
      props.captive.data.conditions &&
      typeof props.captive.data.conditions === 'string'
    ) {
      conditions = props.captive.data.conditions.split(',')
    }

    this.state = {
      conditions: conditions,
      disabled: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.determineChecked = this.determineChecked.bind(this)
  }
  handleSubmit = (e) => {
    e.preventDefault()

    this.props.submitData({ conditions: this.state.conditions.join() })
    this.props.handleNext('conditions')
  }
  handleChange = (event) => {
    const id = event.target.id
    const type = event.target.type
    const val = type === 'checkbox' ? event.target.checked : event.target.value
    let conditions = this.state.conditions

    if (val) {
      if (!conditions.includes(id)) {
        conditions.push(id)
      }
    } else {
      if (conditions.includes(id)) {
        conditions = conditions.filter((item) => item !== id)
      }
    }

    this.setState({ conditions: conditions })
  }

  determineChecked = (id) => {
    return this.state.conditions.includes(id)
  }

  // checkDisabled = () => {
  //   let disabled = this.state.coverage === "" ? "disabled" : ""
  //   this.setState({ disabled: disabled })
  // }

  render = () => (
    <div id="conditions">
      <strong className="h4">
        Have you been treated or diagnosed for any of these medical conditions?*
      </strong>
      <p className="h6">
        <em>*Choose all that apply.</em>
      </p>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col full-width">
            <input
              type="checkbox"
              value="none"
              id="none"
              onChange={this.handleChange}
              checked={this.determineChecked('none')}
            />
            <label htmlFor="none">I have no medical conditions</label>
          </div>
          {conditionList.map(({ key, value }) => (
            <div className="col full-width" key={key}>
              <input
                type="checkbox"
                value={key}
                id={key}
                onChange={this.handleChange}
                checked={this.determineChecked(key)}
              />
              <label htmlFor={key}>{value}</label>
            </div>
          ))}
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={this.state.disabled}
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default Conditions
